import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, Link, useParams, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import Loader from '../../components/Loader';
import { baseUrl } from "../../Config";
import Phase1 from "./Components/Phase1/Phase1";
import RightCards from "./Components/RightCards/RightCards";
import Phase2 from "./Components/Phase2/Phase2";
import Phase3 from "./Components/Phase3/Phase3";
import Phase4 from "./Components/Phase4/Phase4";

const MemberProfile = () => {
    
    const [ loading, setLoading ] = useState(true);
    const [ expanded, setExpanded ] = useState(true);
    const [ project, setProject ] = useState([]);
    const [ allProjects, setAllProjects ] = useState([]);
    const [ impacts, setImpacts ] = useState([]);
    const [ states, setStates ] = useState([]);
    const [ member, setMember ] = useState([]);
    const [ affinidad, setAffinidad ] = useState([]);
    const [ affinidadText, setAffinidadText ] = useState([]);
    const [ projectImpacts, setProjectImpacts ] = useState([]);
    const [ projectStates, setProjectStates ] = useState([]);
    const [ projectVotes, setProjectVotes ] = useState([]);
    const [ ponencias, setPonencias ] = useState([]);
    const [ concilia, setConcilia ] = useState([]);
    const [ activePhase, setActivePhase ] = useState(1);
    const [ activeTab, setActiveTab ] = useState(1);

    const [ newOrigin, setNewOrigin ] = useState({});
    const [ newPonencias, setNewPonencias ] = useState([]);
    const [ newConcilia, setNewConcilia ] = useState([]);
    const [ newProjectStates, setNewProjectStates ] = useState([]);
    const [ newProjectVotes, setNewProjectVotes ] = useState([]);
    const [ selectedOrigin, setSelectedOrigin ] = useState(1);

    const { pathname } = useLocation();
    const params = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);

        addEvents();
    }, [pathname, project]);

    useEffect(() => {
        const fetchProject = () => {
            fetch(`${baseUrl}/api/v1/projects/${params.id}/${params.member}`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setProject(data.data.project);
                setImpacts(data.data.impacts);
                setProjectImpacts(data.data.project_impacts);
                setStates(data.data.states);
                setMember(data.data.member);
                setProjectStates(data.data.project_states);
                setAllProjects(data.data.projects);
                setAffinidad(data.data.affinidad);
                setAffinidadText(data.data.affinidadText);
                setProjectVotes(data.data.projectVotes);
                setPonencias(data.data.ponencias);
                setConcilia(data.data.concilia);

                setNewOrigin(data.data.new_origin);
                setNewPonencias(data.data.new_ponencias);
                setNewConcilia(data.data.new_concilia);
                setNewProjectStates(data.data.new_project_states);
                setNewProjectVotes(data.data.new_projectVotes);

                setLoading(false);
            })
        }
        
        fetchProject();
    }, [])

    const getGradientColor = (score, maxScore) => {
        let percentage = (score * 100) / maxScore;

        if (score == null) {
            return [
                '#F6F6F6',
                '#F6F6F6',
                '#F6F6F6',
            ];
        }

        if (percentage >= 0 && percentage <= 20) {
            return [
                '#FD8383',
                '#f9696b',
                '#ffdddd',
            ]
        } else if (percentage > 20 && percentage <= 40) {
            return [
                '#F6C7A5',
                '#fba977',
                '#ffe6d6',
            ]
        } else if (percentage > 40 && percentage <= 60) {
            return [
                '#FFE399',
                '#ffeb84',
                '#fff8d5',
            ]
        } else if (percentage > 60 && percentage <= 80) {
            return [
                '#BEE883',
                '#b2d581',
                '#efffd9',
            ]
        } else {
            return [
                '#79CFA1',
                '#63be7b',
                '#dcffe5',
            ]
        }
    }

    const addEvents = () => {

        var tabEl = document.querySelectorAll('a[data-bs-toggle="tab"]')

        if (tabEl[0]) {
            // $("#object").on('click', function() {
            //     if ($("#all-text").hasClass("d-none")) {
            //         $("#initial-text").addClass("d-none");
            //         $("#all-text").removeClass("d-none");
            //     } else {
            //         $("#all-text").addClass("d-none");
            //         $("#initial-text").removeClass("d-none");
            //     }
            // });

            $('.ranges').css('display', 'none');

            console.log(tabEl)
            tabEl[0].addEventListener('hidden.bs.tab', function (event) {
                if ($(event.target).data( "bs-target" ) === "#v-pills-profile") {
                    // Tab 1 Active
                    $('.project').css('display', 'block');
                    $('.ranges').css('display', 'none');

                    $('#impacto').addClass('profile-active')
                    $('#impacto-desktop').addClass('profile-active')
                    $('#profile-info-desktop').addClass('extra-border')
                    $('#profile-info').addClass('extra-border')
                    $('.expand-impacto').attr('src', './assets/images/arrow-down-white.png')

                    $('.expand-afinidad').attr('src', './assets/images/arrow-down.png')
                    $('.afinidad-libertad').removeClass('profile-active')
                    $('.afinidad-info-card').removeClass('afinidad-active')
                } else {
                    // Tab 2 Active
                    $('.project').css('display', 'none');
                    $('.ranges').css('display', 'block');

                    $('#impacto').removeClass('profile-active')
                    $('#impacto-desktop').removeClass('profile-active')
                    $('#profile-info-desktop').removeClass('extra-border')
                    $('#profile-info').removeClass('extra-border')
                    $('.expand-impacto').attr('src', './assets/images/arrow-down.png')

                    $('.expand-afinidad').attr('src', './assets/images/arrow-down-white.png')
                    $('.afinidad-libertad').addClass('profile-active')
                    $('.afinidad-info-card').addClass('afinidad-active')
                }
            })

            tabEl[1].addEventListener('hidden.bs.tab', function (event) {
                if ($(event.target).data( "bs-target" ) === "#v-pills-profile") {
                    // Tab 1 Active
                    $('.project').css('display', 'block');
                    $('.ranges').css('display', 'none');

                    $('#impacto').addClass('profile-active')
                    $('#impacto-desktop').addClass('profile-active')
                    $('#profile-info-desktop').addClass('extra-border')
                    $('#profile-info').addClass('extra-border')
                    $('.expand-impacto').attr('src', './assets/images/arrow-down-white.png')

                    $('.expand-afinidad').attr('src', './assets/images/arrow-down.png')
                    $('.afinidad-libertad').removeClass('profile-active')
                    $('.afinidad-info-card').removeClass('afinidad-active')
                } else {
                    // Tab 2 Active
                    $('.project').css('display', 'none');
                    $('.ranges').css('display', 'block');

                    $('#impacto').removeClass('profile-active')
                    $('#impacto-desktop').removeClass('profile-active')
                    $('#profile-info-desktop').removeClass('extra-border')
                    $('#profile-info').removeClass('extra-border')
                    $('.expand-impacto').attr('src', './assets/images/arrow-down.png')

                    $('.expand-afinidad').attr('src', './assets/images/arrow-down-white.png')
                    $('.afinidad-libertad').addClass('profile-active')
                    $('.afinidad-info-card').addClass('afinidad-active')
                }
            })

            tabEl[2].addEventListener('hidden.bs.tab', function (event) {
                if ($(event.target).data( "bs-target" ) === "#v-pills-profile") {
                    // Tab 1 Active
                    $('.project').css('display', 'block');
                    $('.ranges').css('display', 'none');

                    $('#impacto').addClass('profile-active')
                    $('#impacto-desktop').addClass('profile-active')
                    $('#profile-info-desktop').addClass('extra-border')
                    $('#profile-info').addClass('extra-border')
                    $('.expand-impacto').attr('src', './assets/images/arrow-down-white.png')

                    $('.expand-afinidad').attr('src', './assets/images/arrow-down.png')
                    $('.afinidad-libertad').removeClass('profile-active')
                    $('.afinidad-info-card').removeClass('afinidad-active')
                } else {
                    // Tab 2 Active
                    $('.project').css('display', 'none');
                    $('.ranges').css('display', 'block');

                    $('#impacto').removeClass('profile-active')
                    $('#impacto-desktop').removeClass('profile-active')
                    $('#profile-info-desktop').removeClass('extra-border')
                    $('#profile-info').removeClass('extra-border')
                    $('.expand-impacto').attr('src', './assets/images/arrow-down.png')

                    $('.expand-afinidad').attr('src', './assets/images/arrow-down-white.png')
                    $('.afinidad-libertad').addClass('profile-active')
                    $('.afinidad-info-card').addClass('afinidad-active')
                }
            })

            tabEl[3].addEventListener('hidden.bs.tab', function (event) {
                if ($(event.target).data( "bs-target" ) === "#v-pills-profile") {
                    // Tab 1 Active
                    $('.project').css('display', 'block');
                    $('.ranges').css('display', 'none');

                    $('#impacto').addClass('profile-active')
                    $('#impacto-desktop').addClass('profile-active')
                    $('#profile-info-desktop').addClass('extra-border')
                    $('#profile-info').addClass('extra-border')
                    $('.expand-impacto').attr('src', './assets/images/arrow-down-white.png')

                    $('.expand-afinidad').attr('src', './assets/images/arrow-down.png')
                    $('.afinidad-libertad').removeClass('profile-active')
                    $('.afinidad-info-card').removeClass('afinidad-active')
                } else {
                    // Tab 2 ACctive
                    $('.project').css('display', 'none');
                    $('.ranges').css('display', 'block');

                    $('#impacto').removeClass('profile-active')
                    $('#impacto-desktop').removeClass('profile-active')
                    $('#profile-info-desktop').removeClass('extra-border')
                    $('#profile-info').removeClass('extra-border')
                    $('.expand-impacto').attr('src', './assets/images/arrow-down.png')

                    $('.expand-afinidad').attr('src', './assets/images/arrow-down-white.png')
                    $('.afinidad-libertad').addClass('profile-active')
                    $('.afinidad-info-card').addClass('afinidad-active')
                }
            })

            var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            popoverTriggerList.map(function (popoverTriggerEl) {
                return new bootstrap.Popover(popoverTriggerEl) // eslint-disable-line
            })

            // const chartWidth = document.getElementById("chartBox1").getBoundingClientRect().width;
            const ctx = document.getElementById('chartJSContainer').getContext('2d');

            // High Gradient
            const gradientSegment = ctx.createLinearGradient(0, 0, 200, 0);

            gradientSegment.addColorStop(0, getGradientColor(affinidad?.autorias?.value, 100)[0])
            gradientSegment.addColorStop(1, getGradientColor(affinidad?.autorias?.value, 100)[1])

            const ctxc2 = document.getElementById('chartJSContainer2').getContext('2d');

            // Low Gradient
            const gradientSegment2 = ctxc2.createLinearGradient(0, 0, 200, 0);
            gradientSegment2.addColorStop(0, getGradientColor(affinidad?.votaciones?.value, 100)[0])
            gradientSegment2.addColorStop(1, getGradientColor(affinidad?.votaciones?.value, 100)[1])

            const ctxc3 = document.getElementById('chartJSContainer3').getContext('2d');

            // Medium Gradient
            const gradientSegment3 = ctxc3.createLinearGradient(0, 0, 200, 0);
            gradientSegment3.addColorStop(0, getGradientColor(affinidad?.ponencias?.value, 100)[0])
            gradientSegment3.addColorStop(1, getGradientColor(affinidad?.ponencias?.value, 100)[1])

            Chart.defaults.backgroundColor = '#ff0000'; // eslint-disable-line

            const gaugeChartText = {
                id: 'gaugeCharText',
                afterDatasetsDraw (chart, args, pluginOptions) {
                    const { ctx, data, chartArea: { top, bottom, left, right, width, height}, scales: {r} } = chart;  // eslint-disable-line

                    ctx.save();
                    const xCoord = chart.getDatasetMeta(0).data[0].x;
                    const yCoord = chart.getDatasetMeta(0).data[0].y;
                    let score = Number(data.datasets[0].data[0]).toLocaleString('de-DE', {
                        style: 'decimal',
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                    });

                    if (data.datasets[0].data[0] == null) {
                        score = '-';
                    }

                    ctx.font = '36px Poppins';
                    ctx.textColor = '#1A3E58';
                    ctx.fontWeight = 'bold';
                    ctx.textAlign = "center";
                    ctx.textBaseLine = 'bottom';
                    ctx.fillText(score, xCoord, yCoord);
                }
            }

            let data1 = [
                affinidad?.autorias?.value ?? null,
                100 - affinidad?.autorias?.value
            ];

            var options = {
                type: 'doughnut',
                data: {
                    datasets: [{
                        label: '',
                        data: data1,
                        backgroundColor: [
                            gradientSegment,
                            getGradientColor(affinidad?.autorias?.value, 100)[2],
                        ],
                        borderColor: [
                            'transparent',
                            'transparent',
                        ],
                    }]
                },
                options: {
                    rotation: 270, // start angle in degrees
                    circumference: 180, // sweep angle in degrees
                    cutout: '85%',
                    aspectRatio: 1.5,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        }
                    }
                },
                plugins: [
                    gaugeChartText,
                ]
            }

            let data2 = [
                affinidad?.votaciones?.value ?? null,
                100 - affinidad?.votaciones?.value
            ];
            var options1 = {
                type: 'doughnut',
                data: {
                    datasets: [{
                        label: '',
                        data: data2,
                        backgroundColor: [
                            gradientSegment2,
                            getGradientColor(affinidad?.votaciones?.value, 100)[2],
                        ],
                        borderColor: [
                            'transparent',
                            'transparent',
                        ],
                    }]
                },
                options: {
                    rotation: 270, // start angle in degrees
                    circumference: 180, // sweep angle in degrees
                    cutout: '85%',
                    aspectRatio: 1.5,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        }
                    }
                },
                plugins: [
                    gaugeChartText,
                ]
            }

            let data3 = [
                affinidad?.ponencias?.value ?? null,
                100 - affinidad?.ponencias?.value
            ];
            var options2 = {
                type: 'doughnut',
                data: {
                    datasets: [{
                        label: '',
                        data: data3,
                        backgroundColor: [
                            gradientSegment3,
                            getGradientColor(affinidad?.ponencias?.value, 100)[2],
                        ],
                        borderColor: [
                            'transparent',
                            'transparent',
                        ],
                    }]
                },
                options: {
                    rotation: 270, // start angle in degrees
                    circumference: 180, // sweep angle in degrees
                    cutout: '85%',
                    aspectRatio: 1.5,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        }
                    }
                },
                plugins: [
                    gaugeChartText,
                ]
            }

            // var options4 = {
            //     type: 'doughnut',
            //     data: {
            //         datasets: [{
            //             label: '# of Votes',
            //             data: [0.12, 0.13],
            //             backgroundColor: [
            //                 gradientSegment3,
            //                 '#FFF8E5',
            //             ],
            //             borderColor: [
            //                 'transparent',
            //                 'transparent',
            //             ],
            //         }]
            //     },
            //     options: {
            //         rotation: 270, // start angle in degrees
            //         circumference: 180, // sweep angle in degrees
            //         cutout: '85%',
            //         aspectRatio: 1.5,
            //         plugins: {
            //             legend: {
            //                 display: false,
            //             },
            //             tooltip: {
            //                 enabled: false,
            //             }
            //         }
            //     },
            //     plugins: [
            //         gaugeChartText,
            //     ]
            // }

            if (Chart.getChart("chartJSContainer") != undefined) { // eslint-disable-line
                Chart.getChart("chartJSContainer").destroy(); // eslint-disable-line
            }
            let ctx1 = document.getElementById('chartJSContainer').getContext('2d');
            new Chart(ctx1, options); // eslint-disable-line

            if (Chart.getChart("chartJSContainer2") != undefined) { // eslint-disable-line
                Chart.getChart("chartJSContainer2").destroy(); // eslint-disable-line
            }
            let ctx2 = document.getElementById('chartJSContainer2').getContext('2d');
            new Chart(ctx2, options1); // eslint-disable-line

            if (Chart.getChart("chartJSContainer3") != undefined) { // eslint-disable-line
                Chart.getChart("chartJSContainer3").destroy(); // eslint-disable-line
            }
            let ctx3 = document.getElementById('chartJSContainer3').getContext('2d');
            new Chart(ctx3, options2); // eslint-disable-line

            // if (Chart.getChart("chartJSContainer4") != undefined) { // eslint-disable-line
            //     Chart.getChart("chartJSContainer4").destroy(); // eslint-disable-line
            // }
            // let ctx4 = document.getElementById('chartJSContainer4').getContext('2d');
            // new Chart(ctx4, options4); // eslint-disable-line

            if (params.tab == 'afinidad') {
                // var triggerEl = document.querySelector('#v-pills-tab button[data-bs-toggle="tab"]')
                // bootstrap.Tab.getInstance(tabEl[0]).show() // eslint-disable-line
                // tabEl[0].tab('show')
                setActiveTab(2);
                $('#v-pills-profile-tab').trigger('click');
                $('.project').css('display', 'none');
                $('.ranges').css('display', 'block');
                $('.afinidad-libertad').addClass('profile-active')
                $('#impacto-desktop').removeClass('profile-active')
                $('.expand-impacto').attr('src', './assets/images/arrow-down.png')
                $('.expand-afinidad').attr('src', './assets/images/arrow-down-white.png')
            }
        }
    }

    const getVoteDate = (stateId) => {
        let state = projectStates.filter(item => {
            return item.state_id === stateId;
        })

        if (state.length > 0) {
            return state[0].date
        } else {
            return '&nbsp;'
        }
    }

    const getVoteStatus = (stateId) => {
        let state = projectStates.filter(item => {
            return item.state_id === stateId;
        })

        if (state.length > 0) {
            let vote = state[0].vote;

            if (vote === 1) {
                return 'SI'
            } else if (vote === 0) {
                return 'NO'
            } else if (vote === -1) {
                return 'Ausente'
            } else {
                return 'Ausente'
            }
        } else {
            return 'Ausente'
        }
    }

    const getProjectProgress = () => {
        // const maxVotingState = Math.max(...projectStates.map(o => o.state_id));

        if (activePhase === 1) {
            return '12%';
        } else if (activePhase === 2) {
            return '38%';
        } else if (activePhase === 3) {
            return '63%';
        } else if (activePhase === 4) {
            return '100%';
        } else {
            return '0';
        }
    }

    const getProgressColor = (value) => {
        // if (value >= 0 && value <= 20) {
        //     return '#C60A15';
        // } else if (value > 20 && value <= 40) {
        //     return '#F35A04';
        // } else if (value > 40 && value <= 60) {
        //     return '#FFB800';
        // } else if (value > 60 && value <= 80) {
        //     return '#846006';
        // } else {
        //     return '#068440';
        // }

        if (value >= 0 && value <= 30) {
            return '#C60A15';
        } else if (value > 30 && value <= 60) {
            return '#FFB800';
        } else {
            return '#068340';
        }
    }

    const getScore = (score, maxScore) => {
        let percentage = (score * 100) / maxScore;

        if (percentage >= 0 && percentage <= 20) {
            return 'muy-bajo'
        } else if (percentage > 20 && percentage <= 40) {
            return 'baja'
        } else if (percentage > 40 && percentage <= 60) {
            return 'media'
        } else if (percentage > 60 && percentage <= 80) {
            return 'alta'
        } else {
            return 'muy-alta'
        }
    }

    const getStateText = (id) => {
        if (id == 1) {
            return project.phase1;
        } else if (id == 2) {
            return project.phase2;
        } else if (id == 3) {
            return project.phase3;
        } else if (id == 4) {
            return project.phase4;
        } else if (id == 5) {
            return newOrigin.phase1;
        } else if (id == 6) {
            return newOrigin.phase2;
        } else if (id == 7) {
            return newOrigin.phase3;
        } else if (id == 8) {
            return newOrigin.phase4;
        } else {
            return "";
        }
    }

    const getStateDate = (id) => {
        if (id == 1) {
            return project.phase1_date;
        } else if (id == 2) {
            return project.phase2_date;
        } else if (id == 3) {
            return project.phase3_date;
        } else if (id == 4) {
            return project.phase4_date;
        } else {
            return "";
        }
    }

    const getPhaseStatus = (id) => {
        if (id == 1) {
            return project.phase1_status;
        } else if (id == 2) {
            return project.phase2_status;
        } else if (id == 3) {
            return project.phase3_status;
        } else if (id == 4) {
            return project.phase4_status;
        } else if (id == 5) {
            return newOrigin.phase1_status;
        } else if (id == 6) {
            return newOrigin.phase2_status;
        } else if (id == 7) {
            return newOrigin.phase3_status;
        } else if (id == 8) {
            return newOrigin.phase4_status;
        }

        return project.phase1_status;
    }

    const getNewOriginPhaseStatus = (id) => {
        if (id == 1) {
            return newOrigin.phase1_status;
        } else if (id == 2) {
            return newOrigin.phase2_status;
        } else if (id == 3) {
            return newOrigin.phase3_status;
        } else if (id == 4) {
            return newOrigin.phase4_status;
        }

        return newOrigin.phase1_status;
    }

    const getFirstOriginPhaseIcon = (id) => {
        if (id == 1) {
            if (project.phase1_status == 'na') {
                return './assets/images/project/pending.svg';
            } else if (project.phase1_status == 'finished') {
                return './assets/images/project/completed.svg';
            } else {
                return './assets/images/project/ongoing.gif';
            }
        } else if (id == 2) {
            if (project.phase2_status == 'na') {
                return './assets/images/project/pending.svg';
            } else if (project.phase2_status == 'finished') {
                return './assets/images/project/completed.svg';
            } else {
                return './assets/images/project/ongoing.gif';
            }
        } else if (id == 3) {
            if (project.phase3_status == 'na') {
                return './assets/images/project/pending.svg';
            } else if (project.phase3_status == 'finished') {
                return './assets/images/project/completed.svg';
            } else {
                return './assets/images/project/ongoing.gif';
            }
        } else if (id == 4) {
            if (project.phase4_status == 'na') {
                return './assets/images/project/pending.svg';
            } else if (project.phase4_status == 'finished') {
                return './assets/images/project/completed.svg';
            } else {
                return './assets/images/project/ongoing.gif';
            }
        }

        return './assets/images/project/pending.svg'
    }

    const getFirstOriginProgress = () => {
        let percentage = '0%';

        if (project.phase1_status == 'finished') {
            percentage = '12%';
        }

        if (project.phase2_status == 'finished') {
            percentage = '38%';
        }

        if (project.phase3_status == 'finished') {
            percentage = '63%';
        }

        
        if (project.phase4_status == 'finished') {
            percentage = '100%';
        }

        return percentage;
    }

    const getSecondOriginPhaseIcon = (id) => {
        if (id == 1) {
            if (newOrigin.phase1_status == 'na') {
                return './assets/images/project/pending.svg';
            } else if (newOrigin.phase1_status == 'finished') {
                return './assets/images/project/completed.svg';
            } else {
                return './assets/images/project/ongoing.gif';
            }
        } else if (id == 2) {
            if (newOrigin.phase2_status == 'na') {
                return './assets/images/project/pending.svg';
            } else if (newOrigin.phase2_status == 'finished') {
                return './assets/images/project/completed.svg';
            } else {
                return './assets/images/project/ongoing.gif';
            }
        } else if (id == 3) {
            if (newOrigin.phase3_status == 'na') {
                return './assets/images/project/pending.svg';
            } else if (newOrigin.phase3_status == 'finished') {
                return './assets/images/project/completed.svg';
            } else {
                return './assets/images/project/ongoing.gif';
            }
        } else if (id == 4) {
            if (newOrigin.phase4_status == 'na') {
                return './assets/images/project/pending.svg';
            } else if (newOrigin.phase4_status == 'finished') {
                return './assets/images/project/completed.svg';
            } else {
                return './assets/images/project/ongoing.gif';
            }
        }

        return './assets/images/project/pending.svg'
    }

    const getSecondOriginProgress = () => {
        let percentage = '0%';

        if (newOrigin.phase1_status == 'finished') {
            percentage = '12%';
        }

        if (newOrigin.phase2_status == 'finished') {
            percentage = '38%';
        }

        if (newOrigin.phase3_status == 'finished') {
            percentage = '63%';
        }

        
        if (newOrigin.phase4_status == 'finished') {
            percentage = '100%';
        }

        return percentage;
    }

    if (loading) {
        return <Loader/>;
    }

    return <>
        {/* Floating Buttons */}
        <div className="floating-btns">
            { !expanded && (
                <a href="" onClick={(e) => {
                    e.preventDefault();

                    setExpanded(!expanded);
                }} className="expand-arrow">
                    <img src="./assets/images/arrow-left-wt.svg" />
                </a>
            )}

            { expanded && (
                <div className="d-flex gap-2">
                    <a href="" onClick={(e) => {
                        e.preventDefault();

                        setExpanded(!expanded);
                    }} className="collapse-arrow">
                        <img src="./assets/images/arrow-right-wt.svg" />
                    </a>

                    <div className="d-grid gap-2">
                        <a href="./#seating" className="fab">Congresistas</a>
                        <a href="./projects" className="fab">Normatividad</a>
                    </div>
                </div>
            )}
        </div>

        {/* Breadcrumb Section */}
        <div className="breadcrumb project-breadcrumb">
            <div className="overlay"></div>
        </div>

        {/* Project Section */}
        <div className="container-fluid project">            
            {/* Tags */}
            <div className="tags pt-4">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-center" style={{ flexWrap: 'wrap', gap: 15, }}>
                        <h2 className="project-title mb-0">
                            { project.status == 'ongoing' ? (
                                <>
                                    <img src="./assets/images/ongoing.svg" alt="pending" />
                                </>
                            ) : (
                                <>
                                    <img src="./assets/images/done.svg" alt="pending" />
                                </>
                            ) }
                            
                            &nbsp;&nbsp;

                            { project.name }
                        </h2>

                        <div className="d-flex overflow-auto">
                            <img src="./assets/images/project/completed.svg" width={21} height={21} />

                            <span>&nbsp;Completada</span>

                            <img src="./assets/images/project/ongoing.gif" style={{ marginLeft:16 }} width={25} height={21} />

                            <span>&nbsp;Actual</span>

                            <img src="./assets/images/project/pending.svg" style={{ marginLeft:16 }} width={21} height={21} />

                            <span>&nbsp;Pendiente</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Progress */}
            <div className="member-progress">
                <div className="progress-container overflow-auto">

                    <div className="new-timeline">
                        <div className="p-3">
                            <table className="new-timeline-table">
                                <tr>
                                    <td className="first-block-spacer"></td>
                                    {states.map(state => (
                                        <td className="even-box">
                                            <div className={`text-center phase-box mx-auto`} key={state.id}>
                                                <p className={`project-item mb-1 ${activePhase === state.id ? 'active-phase' : ''}`} onClick={() => {
                                                    if (getPhaseStatus(state.id) == 'na') {
                                                        return;
                                                    }

                                                    // setActivePhase(state.id);
                                                }}>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();

                                                        // if (getPhaseStatus(state.id) == 'na') {
                                                        //     return;
                                                        // }

                                                        // setActivePhase(state.id);
                                                    }} tabIndex="0">{state.name}</a>
                                                </p>

                                                <p className="mb-0 project-date"><span dangerouslySetInnerHTML={{ __html: getStateDate(state.id) }}></span></p>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td className="first-block-spacer">
                                        <div className="d-flex gap-2 p-2 align-items-center justify-content-end">
                                            <img src="./assets/images/project/origintag.svg" width={46} />

                                            <span className="origin-name">&nbsp;&nbsp;{ project.origin }</span>
                                        </div>
                                    </td>
                                    <td colSpan={4}>
                                        <div className="gap-2 p-2 align-items-center mobile-origin">
                                            <img src="./assets/images/project/origintag.svg" width={46} />

                                            <span className="origin-name">&nbsp;&nbsp;{ project.origin }</span>
                                        </div>
                                        <div className="progress-visual p-2 timeline-progress">
                                            <div className="progress mt-0 bg-white">
                                                <div className="progress-bar bg-bluegreen" role="progressbar" style={{width: getFirstOriginProgress()}} aria-valuenow={getFirstOriginProgress()} aria-valuemin="0" aria-valuemax="100"></div>                                
                                            </div>

                                            <img
                                                src={getFirstOriginPhaseIcon(1)}
                                                width={21}
                                                className="timeline-tick timeline-phase1"
                                                onClick={() => {
                                                    if (getPhaseStatus(1) == 'na') {
                                                        return;
                                                    }

                                                    setSelectedOrigin(1)
                                                    setActivePhase(1);
                                                }}
                                                data-bs-toggle="popover"
                                                data-bs-placement="top"
                                                data-bs-trigger="hover"
                                                data-bs-html="true"
                                                data-bs-content={`<span className='project-pop'>${getPhaseStatus(1) == 'finished' || getPhaseStatus(1) == 'ongoing' ? getStateText(1) : 'El proyecto aún no ha pasado por está fase'}</span>`}
                                            />

                                            <img
                                                src={getFirstOriginPhaseIcon(2)}
                                                width={21}
                                                className="timeline-tick timeline-phase2" onClick={() => {
                                                    if (getPhaseStatus(2) == 'na') {
                                                        return;
                                                    }

                                                    setSelectedOrigin(1)
                                                    setActivePhase(2);
                                                }}
                                                data-bs-toggle="popover"
                                                data-bs-placement="top"
                                                data-bs-trigger="hover"
                                                data-bs-html="true"
                                                data-bs-content={`<span className='project-pop'>${getPhaseStatus(2) == 'finished' || getPhaseStatus(2) == 'ongoing' ? getStateText(2) : 'El proyecto aún no ha pasado por está fase'}</span>`}
                                            />

                                            <img
                                                src={getFirstOriginPhaseIcon(3)}
                                                width={21}
                                                className="timeline-tick timeline-phase3"
                                                onClick={() => {
                                                    if (getPhaseStatus(3) == 'na') {
                                                        return;
                                                    }

                                                    setSelectedOrigin(1)
                                                    setActivePhase(3);
                                                }}
                                                data-bs-toggle="popover"
                                                data-bs-placement="top"
                                                data-bs-trigger="hover"
                                                data-bs-html="true"
                                                data-bs-content={`<span className='project-pop'>${getPhaseStatus(3) == 'finished' || getPhaseStatus(3) == 'ongoing' ? getStateText(3) : 'El proyecto aún no ha pasado por está fase'}</span>`}
                                            />

                                            <img
                                                src={getFirstOriginPhaseIcon(4)}
                                                width={21}
                                                className="timeline-tick timeline-phase4"
                                                onClick={() => {
                                                    if (getPhaseStatus(4) == 'na') {
                                                        return;
                                                    }

                                                    setSelectedOrigin(1)
                                                    setActivePhase(4);
                                                }}
                                                data-bs-toggle="popover"
                                                data-bs-placement="top"
                                                data-bs-trigger="hover"
                                                data-bs-html="true"
                                                data-bs-content={`<span className='project-pop'>${getPhaseStatus(4) == 'finished' || getPhaseStatus(4) == 'ongoing' ? getStateText(4) : 'El proyecto aún no ha pasado por está fase'}</span>`}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                { project.origin_changed == 1 && (
                                    <tr>
                                        <td className="first-block-spacer">
                                            <div className="d-flex gap-2 p-2 align-items-center justify-content-end">
                                                <span className="origin-name">&nbsp;&nbsp;{ newOrigin.origin }</span>
                                            </div>
                                        </td>
                                        <td colSpan={4}>
                                            <div className="gap-2 p-2 align-items-center mobile-origin">
                                                <span className="origin-name">{ newOrigin.origin }</span>
                                            </div>

                                            <div className="progress-visual p-2 timeline-progress">
                                                <div className="progress mt-0 bg-white">
                                                    <div className="progress-bar bg-bluegreen" role="progressbar" style={{width: getSecondOriginProgress()}} aria-valuenow={getSecondOriginProgress()} aria-valuemin="0" aria-valuemax="100"></div>                                
                                                </div>
                                                
                                                <img
                                                    src={getSecondOriginPhaseIcon(1)}
                                                    width={21}
                                                    className="timeline-tick timeline-phase1"
                                                    onClick={() => {
                                                        if (getNewOriginPhaseStatus(1) == 'na') {
                                                            return;
                                                        }

                                                        setSelectedOrigin(2)
                                                        setActivePhase(1);
                                                    }}
                                                    data-bs-toggle="popover"
                                                    data-bs-placement="top"
                                                    data-bs-trigger="hover"
                                                    data-bs-html="true"
                                                    data-bs-content={`<span className='project-pop'>${getPhaseStatus(5) == 'finished' || getPhaseStatus(5) == 'ongoing' ? getStateText(5) : 'El proyecto aún no ha pasado por está fase'}</span>`}
                                                />
                                                <img
                                                    src={getSecondOriginPhaseIcon(2)}
                                                    width={21}
                                                    className="timeline-tick timeline-phase2"
                                                    onClick={() => {
                                                        if (getNewOriginPhaseStatus(2) == 'na') {
                                                            return;
                                                        }

                                                        setSelectedOrigin(2)
                                                        setActivePhase(2);
                                                    }}
                                                    data-bs-toggle="popover"
                                                    data-bs-placement="top"
                                                    data-bs-trigger="hover"
                                                    data-bs-html="true"
                                                    data-bs-content={`<span className='project-pop'>${getPhaseStatus(6) == 'finished' || getPhaseStatus(6) == 'ongoing' ? getStateText(6) : 'El proyecto aún no ha pasado por está fase'}</span>`}
                                                />
                                                <img
                                                    src={getSecondOriginPhaseIcon(3)}
                                                    width={21}
                                                    className="timeline-tick timeline-phase3"
                                                    onClick={() => {
                                                        if (getNewOriginPhaseStatus(3) == 'na') {
                                                            return;
                                                        }

                                                        setSelectedOrigin(2)
                                                        setActivePhase(3);
                                                    }}
                                                    data-bs-toggle="popover"
                                                    data-bs-placement="top"
                                                    data-bs-trigger="hover"
                                                    data-bs-html="true"
                                                    data-bs-content={`<span className='project-pop'>${getPhaseStatus(7) == 'finished' || getPhaseStatus(7) == 'ongoing' ? getStateText(7) : 'El proyecto aún no ha pasado por está fase'}</span>`}
                                                />
                                                <img
                                                    src={getSecondOriginPhaseIcon(4)}
                                                    width={21}
                                                    className="timeline-tick timeline-phase4"
                                                    onClick={() => {
                                                        if (getNewOriginPhaseStatus(4) == 'na') {
                                                            return;
                                                        }

                                                        setSelectedOrigin(2)
                                                        setActivePhase(4);
                                                    }}
                                                    data-bs-toggle="popover"
                                                    data-bs-placement="top"
                                                    data-bs-trigger="hover"
                                                    data-bs-html="true"
                                                    data-bs-content={`<span className='project-pop'>${getPhaseStatus(8) == 'finished' || getPhaseStatus(8) == 'ongoing' ? getStateText(8) : 'El proyecto aún no ha pasado por está fase'}</span>`}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Mobile Stats */}
        <div className="mobile-stat">
            <div className="container-fluid afinidad-info ranges">
                <div className="chart-stat d-flex justify-content-between bg-transparent">
                    <div className="d-flex justify-content-center align-items-center me-4">
                        <img src="./assets/images/baja.svg" alt="Low" />
                    </div>

                    <div className="d-flex justify-content-center align-items-center me-4">
                        <img src="./assets/images/medio-bajo.svg" alt="Medium" />
                    </div>

                    <div className="d-flex justify-content-center align-items-center me-4">
                        <img src="./assets/images/media.svg" alt="Good" />
                    </div>

                    <div className="d-flex justify-content-center align-items-center me-4">
                        <img src="./assets/images/media-alta.svg" alt="Good" />
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <img src="./assets/images/alta.svg" alt="Good" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid member-information mb-4">
            <div className="information">
                <div className="row">
                    {/* Mobile Info Card */}
                    <div className="col-lg-4 mobile-card">
                        <div className="card">
                            { (params.tab === 'normatividad') ? (
                                <RightCards
                                    project={project}
                                />
                            ) : (
                                <div className="card-body p-4">
                                    <a href="#" className="mobile-profile d-flex justify-content-center align-items-center afinidad-libertad">
                                        AFINIDAD LIBERTAD ECONÓMICA &nbsp; <img className="expand-afinidad" src="./assets/images/arrow-right.png" alt="Arrow Right" />
                                    </a>
            
                                    <div className="avatar text-center mt-4 mb-2 d-flex align-items-center afinidad-info-card">
                                        <div className="vertical-progress">
                                            <div className="progress-value" style={{height: `${member.progress}%`, backgroundColor: getProgressColor(member.progress)}}></div>
                                        </div>

                                        <label className="ms-3 progress-indicator">{member.progress}/100</label>

                                        <div className="mx-auto mobile-member-info">
                                            <h3>{member.name}</h3>
                                            <h4 className="mb-0">{member.position}</h4>

                                            { activePhase != 1 && (
                                                <h4>
                                                    Voto&nbsp;&nbsp;
                                                    { getVoteStatus(activePhase) === 'SI' && (
                                                        <img src="./assets/images/check.svg" style={{ width: 24, height: 24, borderRadius: 0,}} />
                                                    )}

                                                    { getVoteStatus(activePhase) === 'NO' && (
                                                        <img src="./assets/images/close.svg" style={{ width: 24, height: 24, borderRadius: 0,}} />
                                                    )}

                                                    { getVoteStatus(activePhase) === 'Ausente' && (
                                                        <img src="./assets/images/no.svg" style={{ width: 24, height: 24, borderRadius: 0,}} />
                                                    )}
                                                </h4>
                                            )}
                                        </div>
                                        
                                        <img src={`${baseUrl}/members/${member.photo}`} style={{ border: `4px solid ${member.party.color}`, width: 60, height: 60 }} alt="Member Avatar" />
                                    </div>

                                    { project.link4_status == 'enabled' ? (
                                        <a href={project.link4} target="_blank" className="impactobutton">
                                            { project.link4_name }
                                        </a>
                                    ) : (
                                        <a href={'#'} onClick={(e) => {
                                            e.preventDefault();
                                        }} target="_blank" className="impactobutton" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`Próximamente`}>
                                            { project.link4_name }
                                        </a>
                                    )}
            
                                    <a href="#" onClick={(e) => {e.preventDefault()}} className="profile d-flex justify-content-between align-items-center profile-active mt-2" id="impacto">
                                        NORMATIVIDAD &nbsp; <img src="./assets/images/arrow-down-white.png" className="expand-impacto" id="expand" alt="Expand" />
                                    </a>
            
                                    <div className="profile-info mt-4 overflow-hidden collapse-transition extra-border" id="profile-info">
                                        <ul id="list">
                                            { allProjects.length === 0 && (
                                                <p style={{marginBottom: 0,}}>Sin proyectos...</p>
                                            )}

                                            {allProjects.map(project => (
                                                <li key={project.id}><Link to={`/member-profile/${member.id}/project/${project.id}`}>{ project.name }</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-lg-8">
                        {/* Horizontal Nav */}
                        <div className="nav flex-row nav-pills mb-3 mt-3 d-none" id="h-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link normal-tab tab-left-curve active" id="v-pills-home-tab" data-bs-toggle="tab" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">IMPACTO NORMATIVO</a>
                            <a className="nav-link normal-tab tab-right-curve" id="v-pills-profile-tab" data-bs-toggle="tab" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">AFINIDAD LIBERTAD ECONÓMICA</a>
                        </div>

                        <div className="align-items-start">
                            <div className="nav flex-column nav-pills d-none" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className={`nav-link sideway-tab ${params.tab != 'afinidad' ? 'active' : ''}`} id="v-pills-home-tab" data-bs-toggle="tab" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">IMPACTO NORMATIVO</a>
                                <a className={`nav-link sideway-tab ${params.tab == 'afinidad' ? 'active' : ''}`} id="v-pills-profile-tab" data-bs-toggle="tab" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">AFINIDAD LIBERTAD ECONÓMICA</a>
                            </div>

                            {/* Origin Switcher */}
                            <div className="d-flex origin-switch gap-2">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedOrigin(1);
                                }} className={`nav-link origin-tab ${selectedOrigin == 1 ? 'activeTab' : ''}`}>{ project.origin }</a>

                                { project.origin_changed == 1 && (
                                    <>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedOrigin(2);
                                        }} className={`nav-link origin-tab ${selectedOrigin == 2 ? 'activeTab' : ''}`}>{ newOrigin.origin }</a>
                                    </>
                                )}
                            </div>

                            <div className="tab-content w-100 bg-primary" id="v-pills-tabContent">
                                {/* IMPACTO NORMATIVO */}
                                <div className={`tab-pane fade tab-pane-content-box ${activeTab == 1 ? 'show active' : ''}`} id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="container-fluid bg-white rounded-border py-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="tax-reform ps-3">
                                                    { project.titulo_link != null && project.titulo_link != '' ? (
                                                        <>
                                                            <a href={ project.titulo_link } target="_blank">{ project?.titulo }</a>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p>{ project?.titulo }</p>
                                                        </>
                                                    )}
                                                    <p className="project-subtitle">{ project?.short_description }</p>
                                                </div>
                                            </div>
                                        </div>

                                        { selectedOrigin == 1 && (
                                            <>
                                                { activePhase === 1 && (
                                                    <Phase1
                                                        project={project}
                                                    />
                                                )}

                                                { activePhase === 2 && (
                                                    <Phase2
                                                        project={project}
                                                        votes={projectVotes}
                                                        ponencias={ponencias}
                                                    />
                                                )}

                                                { activePhase === 3 && (
                                                    <Phase3
                                                        project={project}
                                                        votes={projectVotes}
                                                        ponencias={ponencias}
                                                    />
                                                )}

                                                
                                                { activePhase === 4 && (
                                                    <Phase4
                                                        project={project}
                                                        votes={projectVotes}
                                                        concilia={concilia}
                                                    />
                                                )}
                                            </>
                                        )}

                                        { selectedOrigin == 2 && (
                                            <>
                                                { activePhase === 1 && (
                                                    <Phase1
                                                        project={newOrigin}
                                                    />
                                                )}

                                                { activePhase === 2 && (
                                                    <Phase2
                                                        project={newOrigin}
                                                        votes={newProjectVotes}
                                                        ponencias={newPonencias}
                                                    />
                                                )}

                                                { activePhase === 3 && (
                                                    <Phase3
                                                        project={newOrigin}
                                                        votes={newProjectVotes}
                                                        ponencias={newPonencias}
                                                    />
                                                )}

                                                
                                                { activePhase === 4 && (
                                                    <Phase4
                                                        project={newOrigin}
                                                        votes={newProjectVotes}
                                                        concilia={newConcilia}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>

                                {/* AFINIDAD LIBERTAD ECONÓMICA */}
                                <div className={`tab-pane fade ${activeTab == 2 ? 'show active' : ''}`} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="container-fluid">
                                        <div className="row afinidad">
                                            <div className="col-lg-4 px-1">
                                                <div className="card left-rounded-border member-profile">
                                                    <div className="text-center chart-header">
                                                        Autorías
                                                    </div>
                                                    <div className="text-center">
                                                        <div id="chartBox1" className="w-80 mb-4 mx-auto">
                                                            <canvas id="chartJSContainer" width="80%" height="200"></canvas>
                                                        </div>
                                                    </div>

                                                    { affinidad?.autorias?.value == null ? (
                                                        <>
                                                            <p style={{whiteSpace: 'pre-wrap', fontSize: 14}} dangerouslySetInnerHTML={{
                                                                __html: affinidad?.autorias?.text
                                                                .replace('#congressmen', member.name)
                                                                .replace('#category', 'Autorías')
                                                                .replace('Autorías', '<b>Autorías</b>')
                                                                .replace('Ponencias', '<b>Ponencias</b>')
                                                                .replace('Votaciones', '<b>Votaciones</b>')
                                                                .replace('afinidad bajo', '<b>afinidad bajo</b>')
                                                            }}></p>

                                                            <div className="mt-auto">-</div>
                                                            <div className="">N/A</div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p style={{whiteSpace: 'pre-wrap', fontSize: 14}} dangerouslySetInnerHTML={{
                                                                __html: affinidadText?.autorias?.text
                                                                .replace('#congressmen', member.name)
                                                                .replace('#range', getScore(affinidad?.autorias?.value, 100))
                                                                .replace('#category', 'Autorías')
                                                                .replace('Autorías', '<b>Autorías</b>')
                                                                .replace('Ponencias', '<b>Ponencias</b>')
                                                                .replace('Votaciones', '<b>Votaciones</b>')
                                                                .replace('afinidad bajo', '<b>afinidad bajo</b>')
                                                            }}></p>

                                                            <div className="mt-auto">0 - 100</div>
                                                            <div className={`${getScore(affinidad?.autorias?.value, 100)} text-capitalize`}>{getScore(affinidad?.autorias?.value, 100).replace('-', ' ')}</div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 px-1">
                                                <div className="card no-border-radius member-profile">
                                                    <div className="text-center chart-header">
                                                        Ponencias
                                                    </div>

                                                    <div className="text-center">
                                                        <div id="chartBox3" className="w-100 mb-4 mx-auto">
                                                            <canvas id="chartJSContainer3" width="100%" height="200"></canvas>
                                                        </div>
                                                    </div>

                                                    {/* <p style={{whiteSpace: 'pre-wrap', fontSize: 14}}>{ affinidadText?.ponencias?.text.replace('#congressmen', member.name).replace('#range', getScore(affinidad?.ponencias?.value, 0.2)).replace('#category', 'Ponencias') }</p> */}
                                                    { affinidad?.ponencias?.value == null ? (
                                                        <>
                                                            <p style={{whiteSpace: 'pre-wrap', fontSize: 14}} dangerouslySetInnerHTML={{
                                                                __html: affinidad?.ponencias?.text
                                                                .replace('#congressmen', member.name)
                                                                .replace('#category', 'Ponencias')
                                                                .replace('Autorías', '<b>Autorías</b>')
                                                                .replace('Ponencias', '<b>Ponencias</b>')
                                                                .replace('Votaciones', '<b>Votaciones</b>')
                                                                .replace('afinidad bajo', '<b>afinidad bajo</b>')
                                                            }}></p>

                                                            <div className="mt-auto">-</div>
                                                            <div className="">N/A</div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p style={{whiteSpace: 'pre-wrap', fontSize: 14}} dangerouslySetInnerHTML={{
                                                                __html: affinidadText?.ponencias?.text
                                                                .replace('#congressmen', member.name)
                                                                .replace('#range', getScore(affinidad?.ponencias?.value, 100))
                                                                .replace('#category', 'Ponencias')
                                                                .replace('Autorías', '<b>Autorías</b>')
                                                                .replace('Ponencias', '<b>Ponencias</b>')
                                                                .replace('Votaciones', '<b>Votaciones</b>')
                                                                .replace('afinidad bajo', '<b>afinidad bajo</b>')
                                                            }}></p>

                                                            <div className="mt-auto">0 - 100</div>
                                                            <div className={`${getScore(affinidad?.ponencias?.value, 100)} text-capitalize`}>{getScore(affinidad?.ponencias?.value, 100).replace('-', ' ')}</div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 px-1">
                                                <div className="card right-rounded-border member-profile">
                                                    <div className="text-center chart-header">
                                                        Votaciones
                                                    </div>

                                                    <div className="text-center">
                                                        <div id="chartBox2" className="w-100 mb-4 mx-auto">
                                                            <canvas id="chartJSContainer2" width="100%" height="200"></canvas>
                                                        </div>
                                                    </div>

                                                    {/* <p style={{whiteSpace: 'pre-wrap', fontSize: 14}}>{ affinidadText?.votaciones?.text.replace('#congressmen', member.name).replace('#range', getScore(affinidad?.votaciones?.value, 0.3)).replace('#category', 'Votaciones') }</p> */}
                                                    { affinidad?.votaciones?.value == null ? (
                                                        <>
                                                            <p style={{whiteSpace: 'pre-wrap', fontSize: 14}} dangerouslySetInnerHTML={{
                                                                __html: affinidad?.votaciones?.text
                                                                .replace('#congressmen', member.name)
                                                                .replace('#category', 'Votaciones')
                                                                .replace('Autorías', '<b>Autorías</b>')
                                                                .replace('Ponencias', '<b>Ponencias</b>')
                                                                .replace('Votaciones', '<b>Votaciones</b>')
                                                                .replace('afinidad bajo', '<b>afinidad bajo</b>')
                                                            }}></p>

                                                            <div className="mt-auto">-</div>
                                                            <div className="">N/A</div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p style={{whiteSpace: 'pre-wrap', fontSize: 14}} dangerouslySetInnerHTML={{
                                                                __html: affinidadText?.votaciones?.text
                                                                .replace('#congressmen', member.name)
                                                                .replace('#range', getScore(affinidad?.votaciones?.value, 100))
                                                                .replace('#category', 'Votaciones')
                                                                .replace('Autorías', '<b>Autorías</b>')
                                                                .replace('Ponencias', '<b>Ponencias</b>')
                                                                .replace('Votaciones', '<b>Votaciones</b>')
                                                                .replace('afinidad bajo', '<b>afinidad bajo</b>')
                                                            }}></p>

                                                            <div className="mt-auto">0 - 100</div>
                                                            <div className={`${getScore(affinidad?.votaciones?.value, 100)} text-capitalize`}>{getScore(affinidad?.votaciones?.value, 100).replace('-', ' ')}</div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ranges">
                            <div className="chart-stat d-flex">
                                <div className="d-flex justify-content-center align-items-center me-4">
                                    <img src="./assets/images/baja.svg" alt="Low" />
                                </div>

                                <div className="d-flex justify-content-center align-items-center me-4">
                                    <img src="./assets/images/medio-bajo.svg" alt="Medium" />
                                </div>

                                <div className="d-flex justify-content-center align-items-center me-4">
                                    <img src="./assets/images/media.svg" alt="Good" />
                                </div>

                                <div className="d-flex justify-content-center align-items-center me-4">
                                    <img src="./assets/images/media-alta.svg" alt="Good" />
                                </div>

                                <div className="d-flex justify-content-center align-items-center">
                                    <img src="./assets/images/alta.svg" alt="Good" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Desktop Info Card */}
                    <div className="col-lg-4 desktop-card">
                        <div className="card">
                            { (params.tab === 'normatividad') ? (
                                <RightCards
                                    project={project}
                                />
                            ) : (
                                <div className="card-body p-0">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        // $('#v-pills-profile-tab').trigger('click');
                                        setActiveTab(2);
                                        $('.project').css('display', 'none');
                                        $('.ranges').css('display', 'block');
                    
                                        $('#impacto').removeClass('profile-active')
                                        $('#impacto-desktop').removeClass('profile-active')
                                        $('#profile-info-desktop').removeClass('extra-border')
                                        $('#profile-info').removeClass('extra-border')
                                        $('.expand-impacto').attr('src', './assets/images/arrow-down.png')
                    
                                        $('.expand-afinidad').attr('src', './assets/images/arrow-down-white.png')
                                        $('.afinidad-libertad').addClass('profile-active')
                                        $('.afinidad-info-card').addClass('afinidad-active')
                                    }} className="profile d-flex justify-content-between align-items-center afinidad-libertad">
                                        AFINIDAD LIBERTAD ECONÓMICA &nbsp; <img className="expand-afinidad" src="./assets/images/arrow-right.png" alt="Arrow Right" />
                                    </a>

                                    <div className="progress mt-2">
                                        <div className="progress-bar" role="progressbar" style={{width: `${member.progress}%`, backgroundColor: getProgressColor(member.progress)}} aria-valuenow={member.progress} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
            
                                    <label className="font-primary-color mt-1 fw-bold">{member.progress}/100</label>
            
                                    <div style={{ maxHeight: 500, overflow: 'auto' }}>
                                        <div className="afinidad-info-card">
                                            <div className="avatar text-center mt-4">
                                                <img src={`${baseUrl}/members/${member.photo}`} style={{ border: `4px solid ${member.party.color}` }} alt="Member Avatar" />
                    
                                                <h3 className="mt-3">{ member.name }</h3>
                                                <h4>{ member.position }</h4>

                                                { activePhase != 1 && (
                                                    <h4>
                                                        Voto&nbsp;&nbsp;
                                                        { getVoteStatus(activePhase) === 'SI' && (
                                                            <img src="./assets/images/check.svg" style={{ width: 24, height: 24, borderRadius: 0,}} />
                                                        )}

                                                        { getVoteStatus(activePhase) === 'NO' && (
                                                            <img src="./assets/images/close.svg" style={{ width: 24, height: 24, borderRadius: 0,}} />
                                                        )}

                                                        { getVoteStatus(activePhase) === 'Ausente' && (
                                                            <img src="./assets/images/no.svg" style={{ width: 24, height: 24, borderRadius: 0,}} />
                                                        )}
                                                    </h4>
                                                )}
                                            </div>
                
                                            <div className="member-info my-3">
                                                <table className="w-100">
                                                    <tbody>
                                                        <tr>
                                                            <td>Partido Político</td>
                                                            <td>{ member.party.name }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Periodo constitucional</td>
                                                            <td>2022 - 2026</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Comisión</td>
                                                            <td>{ member.comission }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Circunscripción</td>
                                                            <td>{ member.cincun }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Votación</td>
                                                            <td>
                                                                { getVoteStatus(activePhase) === 'SI' && (
                                                                    <span>A Favor</span>
                                                                )}

                                                                { getVoteStatus(activePhase) === 'NO' && (
                                                                    <span>En contra</span>
                                                                )}

                                                                { getVoteStatus(activePhase) === 'Ausente' && (
                                                                    <span>N/A</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            { project.link4_status == 'enabled' ? (
                                                <a href={project.link4} target="_blank" className="impactobutton">
                                                    { project.link4_name }
                                                </a>
                                            ) : (
                                                <a href={'#'} onClick={(e) => {
                                                    e.preventDefault();
                                                }} target="_blank" className="impactobutton" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`Próximamente`}>
                                                    { project.link4_name }
                                                </a>
                                            )}
                                        </div>
                
                                        <div className="py-3">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setActiveTab(1);
                                                $('.project').css('display', 'block');
                                                $('.ranges').css('display', 'none');
                            
                                                $('#impacto').addClass('profile-active')
                                                $('#impacto-desktop').addClass('profile-active')
                                                $('#profile-info-desktop').addClass('extra-border')
                                                $('#profile-info').addClass('extra-border')
                                                $('.expand-impacto').attr('src', './assets/images/arrow-down-white.png')
                            
                                                $('.expand-afinidad').attr('src', './assets/images/arrow-down.png')
                                                $('.afinidad-libertad').removeClass('profile-active')
                                                $('.afinidad-info-card').removeClass('afinidad-active')
                                            }} className="profile d-flex justify-content-between align-items-center profile-active" id="impacto-desktop">
                                                NORMATIVIDAD &nbsp; <img src="./assets/images/arrow-down-white.png" className="expand-impacto" id="expand" alt="Expand Icon" />
                                            </a>
                    
                                            <div className="profile-info mt-2 overflow-hidden collapse-transition extra-border" id="profile-info-desktop">
                                                <ul id="list">
                                                    { allProjects.length === 0 && (
                                                        <p style={{marginBottom: 0,}}>Sin proyectos...</p>
                                                    )}

                                                    {allProjects.map(project => (
                                                        <li key={project.id}>
                                                            { project.status == 'ongoing' ? (
                                                                <>
                                                                    <img src="./assets/images/ongoing.svg" alt="pending" style={{ width: 25 }} />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <img src="./assets/images/done.svg" alt="pending" style={{ width: 25 }} />
                                                                </>
                                                            ) }

                                                            &nbsp;&nbsp;

                                                            <Link to={`/member-profile/${member.id}/project/${project.id}`}>{ project.name }</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="desktop-stat">
            <div className="container-fluid afinidad-info mb-4">
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                        <Link to={`../`} onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                        }} className="home-btn" style={{ background: '#F0F0F0', border: 'none' }}>
                            <img src="./assets/images/arrow-left.svg" />&nbsp;
                            Anterior
                        </Link>

                        &nbsp;&nbsp;

                        <Link to={`/`} className="home-btn">
                            <img src="./assets/images/home.svg" style={{ width: 12 }} alt="home icon" />&nbsp;&nbsp;
                            Inicio
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        {/* Modal Ponentes */}
        <div className="modal fade" id="ponentes-modal" tabIndex="-1" aria-labelledby="ponentes-modal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-center author-header">
                                        Ponente(s)&nbsp; &nbsp;&nbsp; &nbsp;
                                        <img src="./assets/images/ponentes.png" alt="Ponentes" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                {project.ponentes?.map(ponentes => (
                                    <div className="col-md-6" key={ponentes.id}>
                                        <a href="#" className="ponentes-modal-text" data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`<img src='./assets/images/flag.png' alt='Party Icon'> ${ponentes.party}`}>{ponentes.name}</a>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-3 d-flex justify-content-center">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Modal Authors */}
        <div className="modal fade" id="authors-modal" tabIndex="-1" aria-labelledby="authors-modal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex align-items-center author-header">
                                        Autor(es)&nbsp; &nbsp;&nbsp; &nbsp;
                                        <img src="./assets/images/author.png" alt="Authors" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                {project.authors?.map(author => (
                                    <div className="col-md-6" key={author.id}>
                                        <a href="#" className="ponentes-modal-text" data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`<img src='./assets/images/flag.png' alt='Author Flag'> ${author.party}`}>{author.name}</a>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-3 d-flex justify-content-center">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default MemberProfile;
